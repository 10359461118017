<template>
    <div class="main">
        <div>
            <router-view></router-view>
            <div v-if="uploader">
                <uploader :options="options" class="uploader-example" @file-success="finishUpload">
                    <uploader-unsupport></uploader-unsupport>
                    <uploader-drop>
                        <p>Drop files here to upload</p>
                    </uploader-drop>
                    <uploader-btn>select files</uploader-btn>
                    <!-- <uploader-btn :attrs="attrs">select images</uploader-btn> -->
                    <uploader-btn :directory="true">select folder</uploader-btn>

                    <uploader-list></uploader-list>
                </uploader>
            </div>

            <!-- <button @click="test">test</button> -->
        </div>
    </div>
</template>
    
<script>
import url from "@/serviceAPI.config.js";
import axios from "axios";
import {
    getStore
} from "@/utils/storage";

export default {
    data() {
        return {
            uploader: true,
            options: {
                // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
                target: url.chunkUpload,
                testChunks: true,
                autoStart: true,
                testMethod: "POST",
                headers:""
            },
            token:""
            // attrs: {
            //     accept: 'image/*' 文件筛选
            // }
        }
    },
    methods: {
        showUploader(judge) {
            this.uploader = judge
        },
        finishUpload(e) {
            console.log(e)
            let fileData = {
                fileName: e.name,
                totalChunks: e.chunks.length,
                identifier: e.uniqueIdentifier
            }
            // file upload finish event
            // console.log(e.chunks.length);

            axios({
                url: url.chunkMerge,
                method: "post",
                data: fileData,
                headers: { Authorization: this.token }
            })
                .then((Response) => {
                    console.log(Response);
                    if (Response.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: "上传成功",
                        });
                    } else {
                        this.$message({
                            type: "success",
                            message: "上传失败，请重新上传",
                        });
                    }
                })
        },

        test() {}

    },
    created(){
        let tkn = getStore("token");
        this.token = tkn
        this.options.headers = {"Authorization": tkn}
    }
}
</script>
    
<style scoped>
a {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    color: #0071E3;
}

/* upload */
.uploader-btn {
    background-color: #409eff;
    color: #fff;
    border-radius: 4px;
    height: 30px;
    width: 30vw;
    font-size: 1rem;
    line-height: 30px;
    border: none;
    margin: 10px;
}

.uploader-drop {
    height: 15vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.uploader-example {
    /* width: 80vw;
        height: 72vh; */
    padding: 20px;
    /* margin: 10px auto; */
    font-size: 1rem;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, .2); */
    overflow: hidden;
}

.uploader-example .uploader-btn {
    margin-right: 4px;
    background-color: #0071E3;
}

.uploader-example .uploader-list {
    max-height: 80vh;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
    